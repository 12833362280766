<template>
    <div class="card">
        <h5>Histórico de Preço</h5>
        
        <div class="card">
            <div class="formgroup-inline">

                <div class="field">
                    <div class="col-12 md:col-10">
                        <AutoComplete id="grupo" placeholder="Grupo" :dropdown="true" :multiple="false" v-model="selectedAutoValue" :suggestions="autoFilteredValue" @complete="selecionarGrupo($event)" field="descricao" :class="{ 'p-invalid': grupoValid }" />
                    </div>
                </div> 
                <div class="field">
                    <div class="col-12 md:col-10">
                        <Dropdown id="mesFiltro" v-model="mes" :options="meses" optionLabel="name" placeholder="Mês" :class="{ 'p-invalid': mesValid }"  />
                    </div>
                </div>
                <div class="field">
                    <div class="col-12 md:col-10">
                        <InputText  v-model="ano" name="ano" id="anoFiltro" type="number" max="2050" min="2000" placeholder="Ano" :class="{'p-invalid': anoValid}" ></InputText>
                    </div>
                </div>  
                <div class="field">
                    <div class="col-12 md:col-10">
                        <Button label="Consultar" @click="getHistoricoPreco()"></Button>  
                    </div>
                </div>
                
            </div>
        </div>

        <DataTable :value="historicoPrecos.todosHistoricos" 
                    :paginator="false"
                    class="p-datatable-gridlines" 
                    dataKey="id" 
                    :rowHover="true" 
					v-model:filters="filters1" 
                    filterDisplay="menu" 
                    :loading="loading1" 
                    :filters="filters1" 
                    responsiveLayout="stack"
					:globalFilterFields="['data','precoManha', 'precoTarde', 'valorManhaPorQuilo', 'valorTardePorQuilo']" >
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                    <span class="p-input-icon-left mb-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
                    </span>
                </div>
            </template>
            <template #empty>
               Nenhum Histórico de Preço encontrado.
            </template>
            <template #loading>
                Carregando dados. Por favor, aguarde!
            </template>
            <!-- <Column field="codigoGrupo" header="Código" style="min-width:12rem;">
                <template #body="{data}">
                    {{data.codigoGrupo}}
                </template>
            </Column> -->
            <Column field="descricao" header="Descrição" style="min-width:12rem" />
            <Column field="fatorConversao" header="Fator Conversão" style="min-width:6rem" />
                
            <Column field="data" header="Data" style="min-width:10rem" sortable>
                <template #body="{data}">
                    {{formatDate(data)}}
                </template>
            </Column>
            <Column field="precoManha" header="Preço Manhã" style="min-width:10rem" sortable>
                <template #body="{data}">
                    {{formatCurrency(data.precoManha)}}
                </template>
            </Column>
            <Column field="precoTarde" header="Preço Tarde" style="min-width:10rem" sortable>
                <template #body="{data}">
                    {{formatCurrency(data.precoTarde)}}
                </template>
            </Column>
            <Column field="valorManhaPorQuilo" header="Valor Manhã/Kg" style="min-width:10rem" sortable>
                <template #body="{data}">
                    {{formatCurrency(data.valorManhaPorQuilo)}}
                </template>
            </Column>
            <Column field="valorTardePorQuilo" header="Valor Tarde/Kg" style="min-width:10rem" sortable>
                <template #body="{data}">
                    {{formatCurrency(data.valorTardePorQuilo)}}
                </template>
            </Column>
        </DataTable>
         

        <div v-if="historicoPrecos.mediaPreco != null" class="surface-section px-4 py-5 md:px-6 lg:px-5" style="border: 1px solid #dfe6ee">
            <div class="text-500 line-height-3">Média de Preços</div>
            <div class="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <div class="font-medium text-3xl text-900">Preço Médio: {{formatCurrency(historicoPrecos.mediaPreco.precoMedio)}}</div>
                    <div class="flex align-items-center text-700 flex-wrap">
                        <div class="mr-5 flex align-items-center mt-3">
                            <i class="pi pi-directions-alt mr-2 text-blue-500"></i>
                            <span>Fator de Conversão: {{historicoPrecos.mediaPreco.fatorConversao}}</span>
                        </div>
                        <div class="mr-5 flex align-items-center mt-3">
                            <i class="pi pi-arrow-down mr-2 text-orange-500"></i>
                            <span>Preço Mínimo: {{formatCurrency(historicoPrecos.mediaPreco.precoMinimo)}}</span>
                        </div>
                        <div class="flex align-items-center mt-3">
                            <i class="pi pi-arrow-up mr-2 text-green-500"></i>
                            <span>Preço Máximo: {{formatCurrency(historicoPrecos.mediaPreco.precoMaximo)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>     
</template>

<script >
    import HistoricoPrecosService from '../../service/HistoricoPrecosService'
    import {FilterMatchMode,FilterOperator} from 'primevue/api'
    //import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'HistoricoPrecos',
        props: {
			matricula: {
				type: Number,
				required: true
			}
		},
        data() {
            return {
                autoValue: null,
                selectedAutoValue: null,
                autoFilteredValue: [],
                historicoPrecos: [],
                mes: null,
                ano: null,
                filters1: null,
				filters2: {},
				loading1: true,
				loading2: true,
                meses: [
                    {name: 'janeiro', code: '01'},
                    {name: 'fevereiro', code: '02'},
                    {name: 'março', code: '03'},
                    {name: 'abril', code: '04'},
                    {name: 'maio', code: '05'},
                    {name: 'junho', code: '06'},
                    {name: 'julho', code: '07'},
                    {name: 'agosto', code: '08'},
                    {name: 'setembro', code: '09'},
                    {name: 'outubro', code: '10'},
                    {name: 'novembro', code: '11'},
                    {name: 'dezembro', code: '12'},
                ],
                grupoValid:false,
                mesValid:false,
                anoValid:false,
            }
        },

		created() {
			this.selecionarGrupo();
            this.initFilters1();
            //this.getHistoricoPreco();
		},

        mounted() {
            HistoricoPrecosService.getTodosGrupos()
                .then((response) => {
                    this.autoValue = response.data; 
                });
                this.loading1 = false;
			    this.loading2 = false;
		},
        methods: {
           selecionarGrupo(event) {
				setTimeout(() => {
                    if(event){
                        if (!event.query.trim().length) {
                            this.autoFilteredValue = [...this.autoValue];
                        }
                        else {
                            this.autoFilteredValue = this.autoValue.filter((grupo) => {
                                return grupo.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                            });
                        }
                    }
				}, 250);
			},

            validarCampos() {
                let isValid = true;

                this.grupoValid = false;
                this.mesValid = false;
                this.anoValid = false;

                if(this.selectedAutoValue == null || this.selectedAutoValue == ''){
                    
                    this.grupoValid = true;
                    isValid = false;
                }
                
                if (this.mes == null || this.mes == ''){
                    
                    this.mesValid = true;
                    isValid = false;
                } 
                
                if(this.ano == null || this.ano == ''){
                    
                    this.anoValid = true;
                    isValid = false;
                } 

                if (!isValid) {
                    
                    this.$toast.add({
                        severity:"error",
                        detail: "Preencher os campos obrigatórios.", 
                        life: 3000
                    });
                }

                return isValid;
            },


            getHistoricoPreco() {
                
                if(!this.validarCampos()){
                    return;
                }               
                
                this.loading1 = true;

                HistoricoPrecosService.getHistoricoPrecos( this.$props.matricula, this.mes.code, this.ano, this.selectedAutoValue.codigo)
                    .then((response) => {
                        this.historicoPrecos = response.data;
                    })
                    .finally(()=>{
                        this.loading1 = false;
                    });
                
            },

            initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					//'codigoGrupo': {value: null, matchMode: FilterMatchMode.CONTAINS},
					//'fatorConversao': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'data': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                    'precoManha': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'precoTarde': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},					
					'valorManhaPorQuilo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'valorTardePorQuilo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				}
			}, 
            
            clearFilter1() {
				this.initFilters1();
			},
            
            formatDate(data) {
                var dataPadrao = new Date(data.data);
                var d = dataPadrao.toLocaleDateString("pt-BR");
                var h = dataPadrao.toLocaleTimeString("pt-BR"); 
                return d;
                //return d + " " + h;

                //return Formatacao.formatDateCustom(data, 'DD-MM-YYYY');
			},
            
            formatCurrency(value) {
				return value ? value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) : 'R$ 0,00';
			},

            // onSort(event) {
                
            //     const sortField = event.sortField;
            //     const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            //     this.sort = `${ sortField }` + `,id,${sortOrder}`;
            //     this.getGrupoClassificacao();
            // },

        }     
       
    }
</script>