<template>
    <HistoricoPrecos :matricula="currentUser?.matricula" />
</template>

<script lang="js">
    
    import HistoricoPrecos from '../../components/consulta/HistoricoPrecos.vue'

    export  default {
        computed: {
			currentUser() {

				return this.$store.state.auth.user;
			
            }
		},

        components: { HistoricoPrecos }
    }

</script>