import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperadoService;
const RESOURCE_V1 = `/v1/historico-precos`;

class HistoricoPrecosService {

    getTodosGrupos() {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/todos-grupos/`);
    }

    getHistoricoPrecos(matricula, mes, ano, grupo) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/buscar-historico/${matricula}/${mes}/${ano}/${grupo}`);
    }
}

export default new HistoricoPrecosService();
